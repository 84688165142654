import { Button } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";

type props = {
  setEnterConfirmationOpen: (open: boolean) => void;
  enterShow: (description: string) => void;
  disabled: boolean;
};

const CheckoutForm = ({
  setEnterConfirmationOpen,
  enterShow,
  disabled,
}: props) => {
  const [paid, setPaid] = useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleEnter = async (event: any) => {
    setPaid(true);
    try {
      await handleSubmit(event);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://beta.easyagility.co.uk",
      },
      redirect: "if_required",
    });

    if (error) {
      // Show error to your customer (for example, payment details incomplete)
      console.error(error.message);
      setPaid(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      enterShow(paymentIntent.description || "");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Button
        onClick={() => setEnterConfirmationOpen(false)}
        style={{ minWidth: "100%", marginTop: "30px" }}
        variant="contained"
        color="secondary"
      >
        Back To Entries
      </Button>
      <Button
        onClick={(e) => {
          handleEnter(e);
        }}
        style={{ minWidth: "100%", marginTop: "10px" }}
        variant="contained"
        disabled={disabled || !stripe || paid}
      >
        Pay and Confirm Entry
      </Button>
      <PaymentElement />
    </form>
  );
};

export default CheckoutForm;
