export type Entry = {
  handler_first_name: string;
  handler_last_name: string;
  handler_name: string;
  classes: number[];
  dog_name: string;
  kennel_club_name?: string;
  kennel_club_number?: string;
  dob?: string;
  height: Height | string;
  grade: Grade | number;
  entry_type: EntryType | string;
  nfc_dogs?: nfcDog[];
  address: string;
};

export type nfcDog = {
  name?: string;
  dob?: string;
};

export enum Height {
  Large = "600",
  Intermediate = "500",
  Medium = "400",
  Small = "300",
  Anysize = "200/300",
  Micro = "250",
}

export enum Grade {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
}

export enum EntryType {
  Full = 1,
  Steeplechase = 2,
  AgilityJumping = 3,
  Saturday = 4,
  Sunday = 5,
  Both = 6,
}
