import {
  Box,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ResultsProps } from "./types";
import PhoenixLogo from "../../PhoenixLogo.png";
import useWindowDimensions from "../../utils/useWindowDimensions";
import BK9 from "../../images/bridgendk9.jpg";
import GR from "../../images/gardensrock.jpg";
import GoRaw from "../../images/go-raw.jpg";
import NDS from "../../images/naturaldogshop.jpg";
import PH from "../../images/poochhooch.jpg";
import Sp from "../../images/spectrum.jpg";
import VCCM from "../../images/vccm.jpg";
import RNJ from "../../images/randj.png";
import WAO from "../../images/wao.jpg";

export type Entry = {
  id: number;
  queued_at?: string;
  partnership?: string;
  time?: number;
  nfc_run?: boolean;
  eliminated?: boolean;
  total_faults?: number;
  run_data?: string[];
};

type Show = {
  name: string;
  date: Date;
  venue: string;
  classes: C[];
};

function srcset(image: any, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

type C = {
  id: number;
  name: string;
  height_grades: any;
};

const Results = ({ showID }: ResultsProps): JSX.Element => {
  if (!showID) showID = 4;

  const { width } = useWindowDimensions();

  const [classValue, setClassValue] = useState<number>(0);
  const [height, setHeight] = useState<string>("");
  const [heightGrades, setHeightGrades] = useState<any>();
  const [heights, setHeights] = useState<string[]>([]);
  const [show, setShow] = useState<Show>({} as Show);

  useEffect(() => {
    getShow();
  }, [showID]);

  const getShow = async () => {
    const response = await axios.get(
      `https://api.easyagility.co.uk/shows/${showID}`
    );

    setShow(response.data);
    setClassValue(response.data?.classes[0].id);
    setHeightGrades(response.data?.classes[0].height_grades);
    if (response.data?.classes[0].height_grades) {
      const heightGrades = response.data?.classes[0].height_grades as any;
      const heights = Object.keys(heightGrades);
      setHeights(heights);
      setHeight(heights[0]);
    }
  };

  const [results, setResults] = useState<any>();

  useEffect(() => {
    getResults();
    const interval = setInterval(() => getResults(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [height, classValue]);

  const getResults = async () => {
    const results: any = {};

    if (heightGrades) {
      const grades = heightGrades[height];

      for (const grade of grades) {
        console.log(grade);
        const response = await axios.get(
          `https://api.easyagility.co.uk/shows/${showID}/classes/${classValue}/results?height=${encodeURIComponent(
            height
          )}&grades=${grade.join(",")}`
        );

        results[grade.join(",")] = response.data as Entry[];
      }

      console.log(results);

      setResults(results);
    }
  };

  const columns = [
    {
      field: "position",
      headerName: "",
      flex: 0.3,
      minWidth: "30",
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.row.eliminated === true ? <>&nbsp;</> : params.value}
          </Box>
        );
      },
    },
    {
      field: "partnership",
      headerName: "Entry",
      flex: 1.5,
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      flex: 0.8,
      minWidth: 80,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.row.eliminated === true ? "E" : params.value}
          </Box>
        );
      },
    },
    {
      field: "total_faults",
      headerName: "F",
      minWidth: 40,
      flex: 0.4,
    },
    {
      field: "run_data",
      headerName: "",
      flex: 0.6,
      minWidth: 60,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              maxHeight: "inherit",
              width: "100%",
              whiteSpace: "initial",
              lineHeight: "16px",
            }}
          >
            {params.value.join(", ")}
          </Box>
        );
      },
    },
  ];

  return (
    <Grid marginTop={3} container spacing={3}>
      <Grid xs={12}>
        <Typography marginBottom={2} variant="h4">
          {/* <img
              style={{ borderRadius: "20px" }}
              width="50"
              height="50"
              src={PhoenixLogo}
            /> */}

          {show.name}
          <Box textAlign={"center"} marginTop={3}>
            <img
              style={{ borderRadius: "20px" }}
              width="200"
              height="100"
              src={WAO}
            />
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id={`class-select-label`}>Select A Class</InputLabel>
          <Select
            labelId={`class-select-label`}
            id={`class-select`}
            label="Select A Class"
            name="select_class"
            value={classValue}
            onChange={(e) => {
              setClassValue(e.target.value as any);
              const heightGrades = show.classes.find(
                (c) => c.id === e.target.value
              )?.height_grades;

              console.log(heightGrades);

              setHeightGrades(heightGrades);
              const heights = Object.keys(heightGrades);
              setHeights(heights);
              setHeight(heights[0]);
            }}
            fullWidth
          >
            {show?.classes?.map((c: C, index: number) => (
              <MenuItem key={index} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id={`height-select-label`}>Select A Height</InputLabel>
          <Select
            labelId={`height-select-label`}
            id={`height-select`}
            label="Select A Height"
            name="select_height"
            fullWidth
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          >
            {heights.map((height, index) => (
              <MenuItem key={index} value={height}>
                {height}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <>
        {results &&
          Object.keys(results).map((grades, index) => (
            <Grid item xs={12}>
              <Box paddingTop={1} bgcolor={"rgb(229, 246, 253)"} key={index}>
                <Typography marginBottom={2} variant="h6">
                  Grades {grades}
                </Typography>
                <DataGrid
                  components={{
                    NoRowsOverlay: () => (
                      <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        No results available yet
                      </Stack>
                    ),
                    NoResultsOverlay: () => (
                      <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        No results yet
                      </Stack>
                    ),
                  }}
                  hideFooter={true}
                  {...({
                    columns: columns,
                    rows: results[grades] || [],
                  } as any)}
                  autoHeight
                />
              </Box>
            </Grid>
          ))}
      </>
      <Box marginTop={5} textAlign={"center"}>
        <Typography textAlign={"center"} variant="h6">
          With Special Thanks To:
        </Typography>
        <ImageList
          sx={{ width: "100vw", height: "100%" }}
          variant="standard"
          cols={width > 1000 ? 8 : 4}
          rowHeight={"auto"}
        >
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(BK9, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(GR, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(GoRaw, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(NDS, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(PH, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(Sp, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(VCCM, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
          <ImageListItem cols={1} rows={1}>
            <img {...srcset(RNJ, 300, 1, 1)} loading="lazy" />
          </ImageListItem>
        </ImageList>
      </Box>
    </Grid>
  );
};

export default Results;
