import { Box, CircularProgress, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CheckoutForm from "../../CheckoutForm/component";
import StripePay from "../../StripePay/component";
import { loadStripe } from "@stripe/stripe-js";

const CardPayment = ({
  amount,
  reference,
  paid,
  setPaid,
}: {
  amount: number;
  reference: string;
  paid: boolean;
  setPaid: (paid: boolean) => void;
}): JSX.Element => {
  const stripePromise = loadStripe(
    "pk_live_51MWjvjCAoxTMQpnD5U9G2bxVn4gYXJlrHbFJIVktWcv356989K261ZJwjpBpLcgBWFHpSYVs5oY5hpIRFp9PWyVb002U43hPTT"
  );

  const [stripeOptions, setStripeOptions] = useState<any>();

  const makePaymentIntent = async () => {
    const response = await axios.post(
      "https://api.easyagility.co.uk/stripe/payment-intent",
      {
        amount: Math.round(amount),
        reference: reference,
      }
    );

    setStripeOptions({ clientSecret: response.data.client_secret });
  };

  useEffect(() => {
    makePaymentIntent();
  }, [amount, reference]);

  return (
    <Box>
      {paid ? (
        <Typography marginTop={2}>
          Thank you for your payment 💸. You'll receive an email shortly
          confirming this. You're all set for this show! Should you now need to
          edit or cancel your entry, then please contact Matt by emailing at
          matt@easyagility.co.uk, or drop a message on Whatsapp at 07984598308.
          🙏
        </Typography>
      ) : (
        <>
          {stripeOptions ? (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <StripePay setPaid={setPaid} />
            </Elements>
          ) : (
            <CircularProgress />
          )}
        </>
      )}
    </Box>
  );
};

export default CardPayment;
