import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";

const RetrieveEntry = ({
  reference,
  setReference,
  retrieveEntry,
}: {
  reference: string;
  setReference: (reference: string) => void;
  retrieveEntry: () => void;
}): JSX.Element => {
  return (
    <Box>
      <Typography fontWeight={"bold"}>Retrieve Your Entry</Typography>
      <Typography marginTop={2}>
        You already have an entry for this show! To retreieve your entry please
        provide the reference code which was sent out in the email when you
        entered. 📧
      </Typography>

      <TextField
        value={reference}
        onChange={(e) => setReference(e.target.value)}
        fullWidth
        id="reference"
        helperText="Reference Code"
        variant="standard"
        margin="dense"
      />

      <Button
        variant="contained"
        color="primary"
        sx={{
          marginRight: 2,
          textTransform: "none",
          marginTop: 2,
          bgcolor: "#8f9c5d",
        }}
        disabled={reference === ""}
        onClick={() => {
          retrieveEntry();
        }}
      >
        Retrieve Entry
      </Button>
    </Box>
  );
};

export default RetrieveEntry;
