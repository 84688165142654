import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Entry } from "../../types/entry";
import Email from "./Email/component";
import EntryComponent from "./Entry/component";
import axios from "axios";
import RetrieveEntry from "./RetrieveEntry/component";
import ShowDetails from "../../types/showDetails";

export type accountInfo = {
  id: number;
  email_address: string;
  dogs: dog[];
  handlers: string[];
};

type dog = {
  name: string;
  grade: number;
  height: string;
  date_of_birth?: string;
  kc_number?: string;
  kc_name?: string;
};

const EntryManagement = ({
  emailAddress,
  setEmailAddress,
  entries,
  setEntries,
  addEntry,
  handleChange,
  handleAddClass,
  handleRemoveClass,
  removeEntry,
  enterShowClick,
  showId,
  showDetails,
}: {
  emailAddress: string;
  setEmailAddress: (emailAddress: string) => void;
  entries: Entry[];
  setEntries: (entries: Entry[]) => void;
  addEntry: () => void;
  handleChange: (index: number, e: any) => void;
  handleAddClass: (index: number, classId: number) => void;
  handleRemoveClass: (index: number, classId: number) => void;
  removeEntry: (index: number) => void;
  enterShowClick: () => void;
  showId: number;
  showDetails: ShowDetails;
}): JSX.Element => {
  const [index, setIndex] = React.useState<number>(0);
  const [hasEntry, setHasEntry] = React.useState<boolean>(false);
  const [accountInfo, setAccountInfo] = React.useState<accountInfo>(
    {} as accountInfo
  );
  const [reference, setReference] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [isMember, setIsMember] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const retrieveEntry = async () => {
    try {
      const response = await axios.get(
        `https://api.easyagility.co.uk/edit-entry/${reference}`
      );

      if (response.data.entries) {
        setHasEntry(false);

        const e = response.data.entries;
        setPaid(false);

        for (const entry of e) {
          entry.handler_name = `${entry.handler_first_name} ${entry.handler_last_name}`;
        }

        setIsMember(response.data.is_member);
        setEntries(e);
        setEditing(true);
      }
    } catch (err) {
      console.log(err);
      setErrorOpen(true);
    }
  };

  const getAccountInfo = async () => {
    try {
      const checkResponse = await axios.get(
        `https://api.easyagility.co.uk/entries/check?email=${emailAddress}&show_id=${showId}`
      );

      if (checkResponse.data.has_entry) {
        setHasEntry(true);
      }

      const response = await axios.get(
        `https://api.easyagility.co.uk/account-info?email=${emailAddress}`
      );

      setAccountInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (emailAddress && emailAddress !== "" && !accountInfo.id)
      getAccountInfo();
  }, [accountInfo, emailAddress]);

  return (
    <Box
      sx={{ borderColor: "#8f9c5d", marginTop: 2 }}
      bgcolor="white"
      padding={4}
      border={1}
      marginTop={3}
      textAlign={"left"}
    >
      {entries.length === 0 ? (
        <Email
          getAccountInfo={getAccountInfo}
          addEntry={addEntry}
          emailAddress={emailAddress}
          setEmailAddress={setEmailAddress}
        />
      ) : hasEntry ? (
        <RetrieveEntry
          reference={reference}
          setReference={setReference}
          retrieveEntry={retrieveEntry}
        />
      ) : (
        <EntryComponent
          showDetails={showDetails}
          editing={editing}
          email={emailAddress}
          showId={showId}
          accountInfo={accountInfo}
          paid={paid}
          setPaid={setPaid}
          removeEntry={removeEntry}
          handleAddClass={handleAddClass}
          handleRemoveClass={handleRemoveClass}
          index={index}
          addEntry={addEntry}
          setIndex={setIndex}
          handleChange={handleChange}
          entry={entries[index]}
          entries={entries}
          totalEntries={entries.length}
          reference={reference}
          setReference={setReference}
          isMember={isMember}
          setIsMember={setIsMember}
        />
      )}

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
          The reference code entered is not correct! Please check your email and
          try again.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EntryManagement;
