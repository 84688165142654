import { Button } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";

type props = {
  setPaid: (paid: boolean) => void;
};

const StripePay = ({ setPaid }: props) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleEnter = async (event: any) => {
    setDisabled(true);
    try {
      await handleSubmit(event);
    } catch (err) {
      setDisabled(false);
      console.log(err);
    }
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (error) {
      setPaid(false);
      setDisabled(false);
      // Show error to your customer (for example, payment details incomplete)
      console.error(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setPaid(true);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        onClick={(e) => {
          handleEnter(e);
        }}
        style={{ minWidth: "100%", marginTop: "10px" }}
        variant="contained"
        disabled={disabled || !stripe}
      >
        Pay and Confirm Entry
      </Button>
    </form>
  );
};

export default StripePay;
